import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Input } from '@strategies/react-form';
import { Modal, Title, Body, Buttons, Button } from '@strategies/ui';


export default observer(function ScenarioModal({ active, onClose, onSave, title }) {
    const { persist } = useStores();
    const [scenarioName, setScenarioName] = useState(persist.scenarioName);
    const [description, setDescription] = useState(persist.description);

    useEffect(() => {
        setScenarioName(persist.scenarioName);
        setDescription(persist.description);
    }, [persist.scenarioName, persist.description])

    const save = useCallback(() => {
        persist.setScenarioName(scenarioName);
        persist.setDescription(description);

        onSave();
        onClose();
    }, [persist, scenarioName, description, onSave, onClose]);

    const cancel = useCallback(() => {
        setScenarioName(persist.scenarioName);
        setDescription(persist.description);

        onClose()
    }, [persist.scenarioName, persist.description, setScenarioName, setDescription, onClose]);

    return (
        <Modal
            className='ScenarioModal'
            active={active}
            onClose={() => cancel()}
        >
            <Title>{title}</Title>

            <Body>
                <Input
                    id="SaveScenarioName"
                    name="Scenario Name"
                    placeholder="Name your new scenario ..."
                    value={scenarioName}
                    onChange={e => setScenarioName(e.target.value)}
                />

                <Input
                    id="SaveDescription"
                    name="Description"
                    placeholder="Describe this scenario ..."
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </Body>

            <Buttons>
                <Button className="secondary" onClick={cancel}>Cancel</Button>

                <Button
                    disabled={scenarioName === ''}
                    onClick={save}
                >
                   Save Scenario
                </Button>
            </Buttons>
        </Modal>
    );
});
