import { stores } from '@strategies/stores';
import {action, computed, makeObservable, observable} from 'mobx';
import Fraction from 'fraction.js';

import DashiProject from '../../../core/models/Project';
import RailsProject from '../models/Project';
import RailsSpecialProject from '../models/SpecialProject';
import { roundCost } from '../util';

import frags2010 from '../assets/data/fragments_blkyear_wl_2010.json';
import frags2020 from '../assets/data/fragments_blkyear_wl_2020.json';

import liveData from '../assets/data/live_wl_10_n0p.json';
import workData from '../assets/data/work_wl_10_n0p.json';
import raceData from '../assets/data/race_wl_10_n0p.json';


const raceKeyMap = {
    R_Hisp: "Hispanic / Latino",
    R_White: "White",
    R_Black: "Black",
    R_AIAN: "Another Race / Multiracial",
    R_Asian: "Asian",
    R_NHOPI: "Another Race / Multiracial",
    R_Oth2: "Another Race / Multiracial",
};

const liveWorkKeyMap = {
    NatMin: "Manufacturing + Construction",
    ManCon: "Manufacturing + Construction",
    TTU: "Trade, Transportation, + Utilities",
    IS: "Information Services",
    Fin: "Financial Activities",
    ProBus: "Professional + Business Services",
    EduHea: "Education + Health Services",
    LeiHosp: "Leisure + Hospitality",
    OthServ: "Other Services",
};


export default class OutputStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    isoTime: string = '5';

    @action
    setIsoTime(isoTime: string) {
        this.isoTime = isoTime;
    }

    @computed
    get activeFragments2010() {
        const { activeProjects } = stores.app;

        return this.getFrags(this.isoTime, frags2010, activeProjects.map((project: DashiProject) => project.id));
    }

    @computed
    get activeFragments2020() {
        const { activeProjects } = stores.app;
        return this.getFrags(this.isoTime, frags2020, activeProjects.map((project: DashiProject) => project.id));
    }

    @computed
    get race() {
        return this.reduceByGeoId('GeoID_2020', this.activeFragments2020, raceData, raceKeyMap);
    }

    @computed
    get work() {
        return this.reduceByGeoId('GeoID_2010', this.activeFragments2010, workData, liveWorkKeyMap);
    }

    @computed
    get live() {
        return this.reduceByGeoId('GeoID_2010', this.activeFragments2010, liveData, liveWorkKeyMap);
    }

    @computed
    get residents() {
        return this.summarize(this.live).toLocaleString();
    }

    @computed
    get workers() {
        return this.summarize(this.work).toLocaleString();
    }

    @computed
    get length() {
        const { activeProjects } = stores.app;
        const fraction = (
            activeProjects
            .map((project: DashiProject) => project as RailsProject|RailsSpecialProject)
            .filter((project: RailsProject|RailsSpecialProject) => !project.isSpecialProject)
            .reduce((length: Fraction, project: RailsProject) => length.add(new Fraction((project as RailsProject).mileLength)), new Fraction(0))
        );

        return `${fraction.toFraction(true)} Mile`;
    }

    private getFrags(isoTime: string, frags: any[], projects: string[]) {
        return frags.filter((frag: any) => (
            frag.iso_wid === isoTime
            && projects.some((project: string) => frag.proj_id_list.indexOf(project) !== -1)
        ));
    }

    private reduceByGeoId(key: string, frags: any[], data: any[], keyMap: {[key:string]: string} = {}): {[key:string]: number} {
        const ans: {[key:string]: number} = {};

        const byId: {[key:string]: number} = data.reduce((byGeoId, record) => {
            const copy = { ...record };
            delete copy['GISJOIN'];
            byGeoId[record.GISJOIN] = copy;
            return byGeoId;
        }, {});

        frags.forEach(frag => {
            const id = frag[key];

            if (id in byId) {
                Object.entries(byId[id]).forEach(([k, v]) => {
                    if (k in keyMap) {
                        k = keyMap[k];
                    }

                    if (!(k in ans)) {
                        ans[k] = 0;
                    }

                    ans[k] += v * frag.PerBlkArea;
                });
            }
        });

        return ans;
    }

    private summarize(metric: {[key:string]:number}) {
        return Math.round(Object.values(metric).reduce((residents, race) => residents + race, 0));
    }

}
