import { stores } from '@strategies/stores';
import {  computed, makeObservable } from 'mobx';
import { Block, time } from '@strategies/react-timeline';

import { randomString } from '../../../core/util';
import DashiAppStore from '../../../core/stores/AppStore';
import RailsProject, { IRailsProjectData } from '../models/Project';
import SpecialProject, { SpecialProjectType, SpecialProjectCost, SpecialProjectTimestamp } from '../models/SpecialProject';
import OutputStore from './OutputStore';
import RaceChart from '../charts/Race';
import SummaryChart from '../charts/Summary';
import {CostByYear} from '../charts/Cost';
import OccupationsResidentsChart from '../charts/OccupationsResidents';
import OccupationsWorkingChart from '../charts/OccupationsWorking';


export default class RailsAppStore extends DashiAppStore {

    outputs: OutputStore;

    constructor() {
        super();
        makeObservable(this);

        this.colorMode = 'type';
        this.setScrubber(68 * time.MONTH);

        this.charts = [
            CostByYear,
            // DebugChart,
            SummaryChart,
            RaceChart,
            OccupationsResidentsChart,
            OccupationsWorkingChart,
        ];

        this.outputs = new OutputStore();
    }

    @computed
    get specialProjectCount() {
        return stores.persist
            .projects
            .filter((project: any) => (project as RailsProject|SpecialProject).isSpecialProject)
            .reduce((counts: any, project: RailsProject|SpecialProject) => {
                if (!(project.type in counts)) {
                    counts[project.type] = 0;
                }

                counts[project.type]++;

                return counts;
            }, {} as {[key:string]: number});
    }

    createProject(data: IRailsProjectData) {
        return new RailsProject(data);
    }

    createProjectFromBlock(block: typeof Block) {
        block.remove();

        return this.createSpecialProject(SpecialProjectType.cafe, block.y, block.start);
    }

    createSpecialProject(type: string, timelineY?: number, start: number = 0) {
        const { design, construction } = SpecialProjectTimestamp[type];
        const { config, persist } = stores;

        return new SpecialProject({
            id: randomString(20),
            mapped: false,
            type,
            name: type,
            timelineY: timelineY || persist.projects.length * (config.timelineBlockHeight + config.timelineRowPadding),
            cost: SpecialProjectCost[type],
            timestamps: {
                design: start,
                start: start + (design * config.periodScale),
                end: start + ((construction + design) * config.periodScale),
            }
        })
    }

}
