import { stores } from '@strategies/stores';
import { computed, makeObservable, override } from 'mobx';

import DashiLayoutStore, { LayoutMode } from '../../../core/stores/LayoutStore';


export default class RailsLayoutStore extends DashiLayoutStore {

    constructor() {
        super();
        makeObservable(this);
    }

    chartPanelMinHeight: number = 380;

    @override
    get layoutMode(): LayoutMode {
        return this.width > 1920 || this.aspectRatio >= 2.2
            ? LayoutMode.B
            : LayoutMode.A;
    }

    @computed
    get specialProjectsPosition() {
        const { ui } = stores;
        const padding = 12;

        return {
            bottom: `${(ui.chartsPanelIsOpen ? this.chartsPanel.size.height : 0) + padding}px`,
            right: `${
                (ui.timelinePanelIsOpen ? this.timelinePanel.size.width : 0) 
                + (!ui.chartsPanelIsOpen ? 45 + padding : 0)
                + padding
            }px`,
        };
    }

}
