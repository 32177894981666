import { observer } from 'mobx-react';

import DashiChart from '../Chart';


@observer
class SummaryChart extends DashiChart {

    static aspect = .66;

    static get label() {
        return 'Summary';
    }

    static get title() {
        return 'Summary';
    }

    render() {
        return (
            <div className="DashiChart SummaryChart" style={this.props.style}>
                <ul>
                    {this.data.map(({ metric, value }) => (
                        <li key={metric}>
                            <h5>{metric}</h5>
                            <p>{value}</p>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}


export default SummaryChart;
