import App from './ui/App';
import './index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from './config';
import AppStore from './stores/AppStore';
import PersistStore from './stores/PersistStore';
import ColorizerStore from './stores/ColorizerStore';
import UIStore from './stores/UIStore';
import LayoutStore from './stores/LayoutStore';

bootstrap(firebaseConfig, config, {
    persist: new PersistStore({}),
    app: new AppStore(),
    colorizer: new ColorizerStore(),
    ui: new UIStore(),
    layout: new LayoutStore(),
});

export default App;
