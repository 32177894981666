import { stores } from '@strategies/stores';
import { makeObservable, override } from 'mobx';
import { observer } from 'mobx-react';

import JobsChart from './JobsChart';
import RailsAppStore from '../stores/AppStore';


@observer
class OccupationsWorking extends JobsChart {

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    static get label() {
        return "Ppl Working By Industry";
    }

    static get title() {
        return "Jobs Within the Park's Service Area";
    }

    get colors() {
        return {
            value: '#66EDFF',
        };
    }

    @override
    get input() {
        return (stores.app as RailsAppStore).outputs.work;
    }

}

export default OccupationsWorking;
