import {computed, makeObservable} from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {IImageLayer} from "./ColorizerImageLayer";

export interface DashiColorizerCanvasProps {
    layers: IImageLayer[];
    height?: number;
    width?: number;
    scale?: number;
    title?: string;
};

/*
* REFACTOR NOTES
* --------------
* DashiColorizerCanvas initially contained a single imageHeight and imageWidth along with a instance of DashiColorizerAssets which
* contained a hard-coded assumption about a base image, a landscape image and a building image
* this has been replaced with an array of layers:IImageLayer[] which can contain any number of layers of various types
* each with their own imageWidth and imageHeight properties to allow sandwiches of variable resolutions
* */

export default class DashiColorizerCanvas {

    readonly layers: IImageLayer[];
    readonly height: number;
    readonly width: number;
    readonly scale: number;
    readonly title: string;
    readonly key: string;

    constructor(props: DashiColorizerCanvasProps) {
        makeObservable(this);
        this.key = uuidv4();
        this.layers = props.layers;
        this.height = props.height || 0;
        this.width = props.width || 0;
        this.scale = props.scale || 1;
        this.title = props.title || '';
    }

    @computed
    get allProjectIds():string[] {
        // return [...assets.landscape.key, ...assets.building.key] //TODO override and use this syntax for CUB
        return []
    }
}
