export default class PixelReader {
    private pixelReaderCanvas: { canvas: HTMLCanvasElement; ctx: CanvasRenderingContext2D | null };

    constructor() {
        const canvas = document.createElement('canvas');
        // canvas.id = 'pixel-reader-canvas';
        canvas.width = 1;
        canvas.height = 1;
        canvas.style.display = 'none';
        // canvas.style.zoom = '10';
        // canvas.style.imageRendering = 'pixelated';
        document.body.appendChild(canvas);
        this.pixelReaderCanvas = {canvas, ctx: canvas.getContext('2d')};
    }

    readPixel(image: CanvasImageSource, x: number, y: number) {
        if (!this.pixelReaderCanvas.ctx) return [0, 0, 0, 0];
        this.pixelReaderCanvas.canvas.width = 1;//clear canvas
        this.pixelReaderCanvas.ctx.drawImage(image, x, y, 1, 1, 0, 0, 1, 1);
        const [r, g, b, a] = Array.from(this.pixelReaderCanvas.ctx.getImageData(0, 0, 1, 1).data);
        return [r, g, b, a];
    }

}

