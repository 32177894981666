import {observer} from 'mobx-react';
import { useStores } from '@strategies/stores';

import Login from './Login';
import MenuBar from './MenuBar';
import ChartsPanel from './ChartsPanel';
import WelcomeModal from './WelcomeModal';
import ProjectSettings from './ProjectSettings';
import SpecialProjects from './SpecialProjects';
import GlobalSettingsModal from './GlobalSettingsModal';
import UpdateScenarioModal from '../../../core/ui/UpdateScenarioModal';
import FileModal from './../../../core/ui/FileModal';
import InfoModal from './../../../core/ui/InfoModal';
import SaveAsModal from './../../../core/ui/SaveAsModal';
import TimelinePanel from './../../../core/ui/TimelinePanel';
import ColorizerStage from './../../../core/ui/Colorizer/components/ColorizerStage';
import DisplaySelectionInfo from "./DisplaySelectionInfo";

export default observer(function DashiApp() {
    const {user} = useStores();

    return (
        <div className="DashiApp">
            {!(user.isLoggedIn && user.isVerified) ? (
                <Login/>
            ) : <>
                <WelcomeModal/>
                <InfoModal/>
                <SaveAsModal/>
                <UpdateScenarioModal/>
                <FileModal/>
                <GlobalSettingsModal/>

                <ColorizerStage/>
                <ProjectSettings/>
                <SpecialProjects/>

                <DisplaySelectionInfo/>

                <div className="panels">
                    <MenuBar/>
                    <TimelinePanel/>
                    <ChartsPanel/>
                </div>
            </>}
        </div>
    );
});
