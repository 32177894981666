import { observer } from 'mobx-react';


type PanelSelectProps<T> = {
    value: T,
    options: T[],
    onChange: (value: T) => void,
}

export default observer(function PanelSelect<T extends string|number>(props: PanelSelectProps<T>) {
    const { value, options, onChange } = props;

    return (
        <div className="PanelSelect">
            <select value={value} onChange={e => onChange(e.target.value as T)}>
                {options.map(option =>
                    <option key={option} value={option}>{option}</option>
                )}
            </select>
        </div>
    );
});
