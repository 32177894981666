import { stores } from '@strategies/stores';
import { Component } from 'react';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import ChartData from '../../types/ChartData';


type ScrubberInfo = {
    value: number;
    info: string;
};

export type DashiChartProps = {
    width: number;
    height: number;
    style: {[key:string]: string};
    scrubber: ScrubberInfo;
};

@observer
class DashiChart extends Component<DashiChartProps, {}> {

    constructor(props: DashiChartProps) {
        super(props);
        makeObservable(this);
    }

    @computed
    get data(): ChartData {
        return [];
    }

    get options() {
        return {};
    }

    get colors(): {[key:string]: string} {
        throw new Error('DashiChart needs colors');
    }

    static get label(): string {
        throw new Error('DashiChart needs a label');
    }

    static get title(): string {
        throw new Error('DashiChart needs a title');
    }

    static selectedOnLoad: boolean = false;

    static aspect: number|null = null;

    @computed
    static get requiredWidth() {
        return stores.layout.innerChartArea.size.height * (this.aspect || 1);
    }

    @computed
    static get isDisabled() {
        return false;
    }

    x: string = '';
    y: string = '';

    // Needed to access inherited class' static members from parent classes
    get static() {
        return this.constructor as typeof DashiChart;
    }

    render() {
        console.warn('chart has no render method implemented');
        return <></>;
    }

    formatValue(value: number): string {
        return value.toLocaleString();
    }
}

export default DashiChart;
