import { useStores } from '@strategies/stores';
import {Group, Input, Label, RadioSlider, Select} from '@strategies/react-form';
import {observer} from 'mobx-react';

import ProjectSettings from '../../../../core/ui/ProjectSettings';
import {FINISH_LEVELS} from '../../models/Project';
import {SpecialProjectType} from '../../models/SpecialProject';
import { roundCost } from '../../util';

import HardCutActive from '../../assets/images/HardCut-Active-02.jpg';
import HardCutPassive from '../../assets/images/HardCut-Preserve-02.jpg';
import SoftCutActive from '../../assets/images/SoftCut-Active-02.jpg';
import SoftCutPassive from '../../assets/images/SoftCut-Preserve-02.jpg';
import TunnelActive from '../../assets/images/Tunnel-Active-02.jpg';
import TunnelPassive from '../../assets/images/Tunnel-Preserve-02.jpg';
import ViaductActive from '../../assets/images/Viaduct-Active-02.jpg';
import ViaductPassive from '../../assets/images/Viaduct-Preserve-02.jpg';


const AXONS = {
    "Hard Cut": {
        active: HardCutActive,
        passive: HardCutPassive,
    },
    "Soft Cut": {
        active: SoftCutActive,
        passive: SoftCutPassive,
    },
    "Viaduct": {
        active: ViaductActive,
        passive: ViaductPassive,
    },
    "Tunnel": {
        active: TunnelActive,
        passive: TunnelPassive,
    },
};


export default observer(function RailsProjectSettings() {
    const { app, persist, ui } = useStores();
    const project = app.selectedProject;

    return (
        <ProjectSettings title={'Segment Data'}>
            {project && <>
                <section className="top">
                    <Group>
                        <Input name="Project Name" className="project-name" value={project.name}
                               onChange={val => project.setName(val)}/>
                        {project.isSpecialProject || (
                            <Group name="Project Length" className="hide-labels">
                                <Input readonly={true} value={project.mileLength} left="~" right={'Mile'}/>
                            </Group>
                        )}
                    </Group>
                </section>

                {project.isSpecialProject || <>
                    <section className="info">
                        <Group>
                            <Input name="Type" value={project.type} readonly={true}/>
                            <Input name="Neighborhood" value={project.neighborhood} readonly={true}/>
                            <Input name="Location" value={project.location} readonly={true}/>
                        </Group>

                        <Group>
                            <Input name="Ownership" value={project.ownership || '-'} readonly={true}/>
                            <Input name="Other" value={project.other || '-'} readonly={true}/>
                        </Group>
                    </section>

                    <section className="infrastructure">
                        <Group name="Infrastructure">
                            <div className="cost-sibling" />
                            <Input name="Cost" type={'number'} value={roundCost(project.infrastructure)} left={'$'}
                                   readonly={true}/>
                        </Group>
                    </section>

                    <section className="access">
                        <Group>
                            <div className="cost-sibling">
                                <Label name="Access Option">
                                    <AccessOption option={'Elevator'}/>
                                    <AccessOption option={'Ramp'}/>
                                    <AccessOption option={'Stairs'}/>

                                    {project.groundCost !== null && (
                                        <AccessOption option={'Ground'}/>
                                    )}
                                </Label>
                            </div>
                            <Input name="Access Cost" type={"number"} value={roundCost(project.accessCost)} left={'$'}
                                   readonly={true}/>
                        </Group>
                    </section>

                    <section className="landscape">
                        <Group>
                            <div className="cost-sibling">
                                <RadioSlider
                                    name="Landscape Finish"
                                    className="secondary"
                                    value={project.finish_level}
                                    options={Object.keys(FINISH_LEVELS)}
                                    onChange={val => project.setFinishLevel(val)}
                                />

                                <div className="finish-axons">
                                    <div>
                                        <img src={AXONS[project.type].passive} alt="" />
                                    </div>

                                    <div>
                                        <img src={AXONS[project.type].active} alt="" />
                                    </div>
                                </div>
                            </div>
                            <Input name="Cost" type={"number"} value={roundCost(project.landscapeFinishCost)} left={'$'}
                                   readonly={true}/>
                        </Group>

                        <Group>
                            <div className="cost-sibling"/>
                            <Input name="Project Cost" type="number" value={roundCost(project.totalCost)} left="$"
                                   readonly={true}/>
                        </Group>
                    </section>
                </>}

                {project.isSpecialProject && (
                    <section className="special-project-info">
                        <Group>
                            <div className="cost-sibling">
                                <Select
                                    name="Project Type"
                                    options={Object.values(SpecialProjectType)}
                                    value={project.type}
                                    onChange={val => project.setType(val)}
                                />
                            </div>
                            <Input type="number" readonly={true} name="Project Cost" value={roundCost(project.totalCost)}
                                   left="$"/>
                        </Group>
                    </section>
                )}

                <section className="fundraising">
                    <Group>
                        <div className="cost-sibling">
                            <Input name="Fundraising Benchmark" type="number" value={persist.fundraising}
                                   readonly={true} left="$" right="Goal"/>
                            <button onClick={() => ui.setGlobalSettingsModalOpen()}>
                                Change
                            </button>
                        </div>

                        <Input left="%" readonly={true}
                               value={(100 * project.totalCost / persist.fundraising).toFixed(2)}/>
                    </Group>
                </section>
            </>}
        </ProjectSettings>
    );
});


var AccessOption = observer(function AccessOption({option}) {
    const {selectedProject: project} = useStores().app;

    return (
        <Group className="AccessOption">
            <label htmlFor={`access[${option}]`}>{option}</label>

            <Input
                type="number"
                left="$"
                value={roundCost(project[`${option.toLowerCase()}Cost`] * project.escalation)}
                readonly={true}
            />

            <Input
                htmlFor={`access[${option}]`}
                type="increment"
                left={'X'}
                value={project[option.toLowerCase()]}
                onChange={val => project[`set${option}`](val)}
            />
        </Group>
    );
});
