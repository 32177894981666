import { makeObservable, override } from 'mobx';
import { stores } from '@strategies/stores';
import { observer } from 'mobx-react';

import SummaryChart from '../../../core/ui/charts/SummaryChart';
import { DashiChartProps } from '../../../core/ui/charts/Chart';

import MetricView from "../../../core/models/MetricView";
import { IRailsProject } from "../models/Project";
import { formatDollars } from "../../../core/util";


@observer
class Summary extends SummaryChart {

    private costView: MetricView = new MetricView((p) => (p as unknown as IRailsProject).chartableCost);

    constructor(props: DashiChartProps) {
        super(props);
        makeObservable(this);
    }

    static selectedOnLoad = true;

    @override
    get data() {
        const { length, residents, workers } = stores.app.outputs;

        return [
            { metric: 'Scenario Total Cost', value: formatDollars(this.costView.cumulativeCurrent) },
            { metric: 'Total Residents', value: residents },
            { metric: 'Total Workers', value: workers, },
            { metric: 'Park Build Length', value: length },
        ];
    }
}


export default Summary;
