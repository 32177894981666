import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';

import Checkbox from "../Checkbox/Checkbox";
import DashiProject from "../../models/Project";


export default observer(function ProjectList() {
    const { app } = useStores();

    return (
        <div className={'ProjectList'}>
            {app.projects.map((project: DashiProject) => (
                <div key={project.id} className={'ProjectCard' + (project.selected ? ' selected' : '') + (project.isExcluded ? ' disabled' : '')}>
                    <div 
                        className="clickable-title" 
                        style={{ backgroundColor: project.color }}
                        onClick={e => app.selectProject(project, e.ctrlKey)}
                    >
                        <h3>{project.displayName}</h3>

                        <Checkbox 
                            value={!project.isExcluded} 
                            onClick={e => { 
                                e.stopPropagation(); 
                                project.setIsExcluded(!project.isExcluded)
                            }}
                        />
                    </div>

                    <div className="inner">
                        <div className={'data-element row'}>
                            <div className={'name'}>
                                {app.colorMode.split('_').join(' ')}
                            </div>

                            <div className={'value'}>
                                {
                                    //@ts-ignore
                                    project[app.colorMode]
                                }
                            </div>
                        </div>

                        {project.cardInfo && project.cardInfo.map(({ label, value }) => (
                            <div key={label} className={'data-element row'}>
                                <div className={'name'}>
                                    {label}
                                </div>
                                <div className={'value'}>
                                    {value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
});
