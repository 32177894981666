import { Store } from '@strategies/stores';

import DashiConfig from '../config';
import FirebaseConfig from '../types/FirebaseConfig';


export default class ConfigStore extends Store {

    constructor(config: typeof DashiConfig & FirebaseConfig) {
        super();

        for (let key in config) {
            // @ts-ignore
            this[key] = config[key];
        }
    }

}
