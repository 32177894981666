import {useEffect} from 'react';
import {observer} from "mobx-react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import { useStores } from '@strategies/stores';

import ColorizerStack from "./ColorizerStack";
import ToolBar from '../../ToolBar';
import canvasIcon from '../../assets/grid.svg'

const ZoomableStageContent = observer(function ({
                                                    zoomIn,
                                                    zoomOut,
                                                    zoomToElement
                                                }: { zoomIn: () => void, zoomOut: () => void, zoomToElement: (id:string) => void }) {
    const {colorizer, layout} = useStores();
    const {height, width, canvases} = colorizer;

    useEffect(() => {
        zoomToElement('All');
    }, []); // eslint-disable-line

    const calcPosition = (canvas: any, i: number) => {
        if (canvases.length === 1) {
            return [0,0];
        }
        const midX = width * 0.55;
        const midY = height * 0.45;
        let col = i % 2;
        let row = Math.floor(i / 2);
        let x, y;
        if (col === 0) {
            x = midX - canvas.width;
        } else {
            x = midX;
        }
        if (row === 0) {

            y = midY - canvas.height;
        } else {
            y = midY;
        }
        return [x, y];
    }

    const extraWidth = width * layout.width / layout.colorizerWindow.size.width;
    const extraHeight = height * layout.height / layout.colorizerWindow.size.height;

    // console.log('RENDER COLORIZER STAGE: ', canvases);

    const zoomElements = [...canvases.map((c: any) => c.title)]
    if (canvases.length > 1) zoomElements.unshift('All');
    return <><ToolBar zoomIn={() => zoomIn()} zoomOut={() => zoomOut()} menuButtons={[
        {
            alt: 'zoom to canvas icon',
            icon: canvasIcon,
            items: zoomElements.map(name => ({
                name: name,
                onClick: () => zoomToElement(name)
            }))
        }
    ]}/>

        <TransformComponent wrapperClass='stage-wrapper' contentClass='stage-transform'>
            <div className="stage" style={{width, height}}>
                {canvases.map((canvas: any, i: number) => (
                    <ColorizerStack
                        key={canvas.key}
                        id={canvas.title}
                        dashiColorizerCanvas={canvas}
                        position={calcPosition(canvas, i)}
                        multipleCanvasMode={canvases.length > 1}
                        onButtonClick={() => zoomToElement(canvas.title)}
                    />
                ))}
            </div>
            <div id={'All'} className={'zoom-to-extra'} style={{width: extraWidth, height: extraHeight}}/>

        </TransformComponent>
    </>;
});

export default observer(function ColorizerStage() {
    return (
        <div className={'ColorizerStage'}>
            <TransformWrapper
                minScale={0.1}
                maxScale={1.5}
                initialScale={1}
                initialPositionX={200}
                initialPositionY={100}
                limitToBounds={false}
                velocityAnimation={{disabled: true}}
            >
                {({zoomIn, zoomOut, zoomToElement}) => <ZoomableStageContent
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    zoomToElement={zoomToElement}/>}
            </TransformWrapper>
        </div>
    );
});
