import { observer } from "mobx-react";
import { stores } from '@strategies/stores';

import {ColorHexAlpha, ColorizerStateImages, GradientUpdateMethod} from "./ColorizerStateImages";
import {glslCore, ReglCanvas, ReglCanvasProps} from "./ReglCanvas";
import ColorizerEncodings from "../helpers/ColorizerEncodings";
import {ColorizerLayer} from "../../../models/ColorizerImageLayer";

const frag = () => {
    // language=GLSL
    return `
        precision mediump float;
        #define MAX_STACK 4

        ${glslCore()}
        //        #define N_ARR 41

        const int max_its = 1000;//max number of entities on 1 pixel

        //const int MAX_STACK = 5;//max allowed number of blended colors stacked on each other

        vec4 blendArr(vec4 stack[MAX_STACK]) {
            int stackLen = 0;
            vec4 color = stack[0];
            for (int c = 1; c < MAX_STACK; c++) {
                if (stack[c].a > 0.) {
                    stackLen = stackLen + 1;
                    color = blendColors(stack[c], color);
                }
            }
            return color;
        }

        uniform sampler2D textureReference;
        uniform sampler2D textureCurrent;
        uniform sampler2D textureData;
        uniform int dataWidth;
        uniform int idCount;

        varying vec2 uv;

        void decode(int i, float tw, out int v_type, out int v_lightness, out int v_idx, out vec4 colorAtIdx) {
            float x = mod2(float(i), tw);
            float y = floor2(float(i)/tw);//
            vec2 dataTileCoord = vec2((0.5+x)/tw, (0.5+y)/tw);
            vec4 color = texture2D(textureData, dataTileCoord);

            int cVal = toValInt(color);
            v_type = extractVal(cVal, 0, 2);
            v_lightness = extractVal(cVal, 2, 8);
            v_idx = extractVal(cVal, 10, 14);

            colorAtIdx = texture2D(textureCurrent, vec2((0.5+float(v_idx))/float(idCount), 0.5));
        }

        void main () {
            vec2 v_texcoord = vec2(uv.s, 1.0 - uv.t);
            float tw = float(dataWidth);
            vec4 c = texture2D(textureReference, v_texcoord);
            if (c.a == 0.0) {
                gl_FragColor = vec4(0);
                return;
            }
            int cVal = toValInt(c);

            int v_len = extractVal(cVal, 0, 6);
            int v_start = extractVal(cVal, 6, 18);

            int v_stop = v_start + v_len;

            gl_FragColor = vec4(0.);

            vec4 stack[MAX_STACK];
            int stackPos = 0;

            //first 'layer' - render shadows
            for (int c = 0; c < max_its; c++) {
                int i = c + v_start;
                if (i >= v_stop) { break; }

                int v_type, v_lightness, v_idx;
                vec4 colorAtIdx;
                decode(i, tw, v_type, v_lightness, v_idx, colorAtIdx);

                //'NonColoredBg'
                if (v_type == 0 && colorAtIdx.a > 0.) { //check if this shadow belongs to a visible object
                    if (stackPos == 0) { //allow only 1 shadow
                        for (int k = 0; k < MAX_STACK; ++k) { //GLSL 'Push' https://stackoverflow.com/questions/30585265/what-can-i-use-as-an-array-index-in-glsl-in-webgl
                            if (stackPos == k) {
                                stack[k] = vec4(0.1, 0.1, 0.1, 0.5);
                            }
                        }

                        stackPos = stackPos+1;
                    }
                }
            }

            int maxLightness = 0;
            for (int c = 0; c < max_its; c++) {
                //                    int i = v_stop - c - 1;//reverse order - as pixels start at back
                //                    if (i < v_start) { break; }

                int i = c + v_start;
                if (i >= v_stop) { break; }

                int v_type, v_lightness, v_idx;
                vec4 colorAtIdx;
                decode(i, tw, v_type, v_lightness, v_idx, colorAtIdx);

                if (colorAtIdx.a > 0. && colorAtIdx.r == 0.0) { //BLEND USING LIGHTEST
                    for (int k = 0; k < MAX_STACK; ++k) {
                        if (stackPos == k) {
                            vec4 color;
                            if (v_lightness > maxLightness) {
                                maxLightness = v_lightness;
                                //                                color = applyLightness(vec4(0., 0.5, 0.5, 0.4), float(v_lightness)/127.);
                                stack[0] = vec4(0.1, 0.1, 0.1, 1.0- float(maxLightness)/255.);
                            }
                        }
                    }
                    stackPos = stackPos+1;
                } else {
                    //'NonColoredBg', 'ColoredFg', 'NonColoredFg', 'ColoredFgEdge'
                    if (v_type > 0 && colorAtIdx.a > 0.)  {
                        for (int k = 0; k < MAX_STACK; ++k) { //GLSL 'Push' https://stackoverflow.com/questions/30585265/what-can-i-use-as-an-array-index-in-glsl-in-webgl
                            if (stackPos == k) {
                                vec4 color;
                                if (v_type == 1) { //'ColoredFg'
                                    color = applyLightness(vec4(colorAtIdx.rgb, 1.), float(v_lightness)/255.);
                                }
                                if (v_type == 2) { //'NonColoredFg'
                                    color = vec4(vec3(0.), 1.-float(v_lightness)/255.);
                                }
                                if (v_type == 1) { //'ColoredFg'
                                    stack[k] = styleFromAlphaEncoding(color, colorAtIdx.a, float(v_lightness)/255.);
                                } else if (v_type == 2 && colorAtIdx.a > 0.79 && colorAtIdx.a<= 0.81) {
                                    //'NonColoredFg' - hack to match colored stroke to 'fractalish' selection state
                                    stack[k] = vec4(0.3882,0.4117,0.77647, 1.-float(v_lightness)/255.);
                                }  else if (v_type == 2 && colorAtIdx.a > 0.09 && colorAtIdx.a<= 0.11) {
                                    //0.1 alpha used for 'filtered out' state
                                    stack[k] = vec4(0.6,0.,0., 1.-float(v_lightness)/255.);
                                } else {    
                                    stack[k] = color;
                                }
                            }
                        }
                        stackPos = stackPos+1;
                    }

                }
            }


            vec4 blended = blendArr(stack);

            gl_FragColor = blended;
        }
    `;
}
type ColorizerCanvasProps = ReglCanvasProps & {canvasId:string, layer: ColorizerLayer};

class ColorizerCanvas extends ReglCanvas<ColorizerCanvasProps> {
    dataWidth: number;
    dataImage: any;
    colorizerEncodings?:ColorizerEncodings;
    updateGradient?: GradientUpdateMethod;
    currentValues?: ColorHexAlpha[];
    colorStateImage?: ColorizerStateImages;

    constructor(props: any) {
        super(props);

        this.dataWidth = stores.config.colorizerDataWidth;
    }

    drawConfig(regl:any, vert:string) {
        return {
            vert,
            frag: frag(),
            attributes: {
                position: [
                    0, 4,
                    -4, -4,
                    4, -4,
                ]
            },

            uniforms: {
                textureReference: regl.prop('reference'),
                textureData: regl.prop('data'),
                textureCurrent: regl.prop('current'),
                dataWidth: regl.prop('dataWidth'),
                idCount: regl.prop('idCount'),
            },

            count: 3
        }
    }

    drawFrameProps() {
        const {regl} = this;
        const {layer} = this.props;

        if (!regl || !this.colorStateImage || !layer) return null;

        const {canvas, update, values} = this.colorStateImage.updateGradient(layer.colorizerStates);
        if (!update) return null;
        this.currentValues = values;
        const canvasGradTexture = regl.texture(canvas);
        return {
            current: canvasGradTexture,
            data: this.loadedTextures['data'],
            reference: this.loadedTextures['reference'],
            dataWidth: this.dataWidth,
            idCount: this.nArr,
        }
    }

    withHitPixel(r:number, g:number, b:number, a:number, ctrlKey:boolean, shiftKey:boolean) {
        if (!this.currentValues) return;

        if (!this.colorizerEncodings && this.dataImage) {
            this.colorizerEncodings = new ColorizerEncodings(this.dataImage);
        }
        const ans = this.colorizerEncodings?.getValuesForEncoded(r, g, b);
        let colorizer = stores.colorizer;

        if (a === 0) {
            colorizer.emitProjectClick(null, ctrlKey, shiftKey);
            return;
        }
        //trigger event on top-most VISIBLE "ColoredFg" px
        if (ans && ans.length > 0) {
            ans.reverse();//top-down so first one found is on top
            const values = this.currentValues;
            if (values) {
                const topHit = ans.find((elem, i) => {
                    return elem.type === 'ColoredFg' && (values[elem.idx] !== null);
                });
                if (topHit) {
                    colorizer.emitProjectClick(this.keyIndex[topHit.idx], ctrlKey, shiftKey);
                } else {
                    colorizer.emitProjectClick(null, ctrlKey, shiftKey);
                }
            }
        }
    }

    async loadImages(keyIndex:string[]) {
        const { regl } = this;
        const { layer } = this.props;
        if (!regl || !layer || !layer.paths) return;

        this.colorStateImage = new ColorizerStateImages(keyIndex, true);

        this.dataImage = await this.loadFromSrc(layer.paths.data);
        this.loadedTextures['data'] = regl.texture(this.dataImage);

        const { canvas, values } = this.colorStateImage.updateGradient(layer.colorizerStates);
        this.currentValues = values;
        this.loadedTextures['current'] = regl.texture(canvas);

        this.idImage = await this.loadFromSrc(layer.paths.graphic) as CanvasImageSource;
        this.loadedTextures['reference'] = regl.texture(this.idImage);
    };

    loadKeyIndex():string[] {
        const { layer } = this.props;
        if (!layer || !layer.paths) return [];

        return layer.paths.key;
    }

}

export default observer(ColorizerCanvas);
