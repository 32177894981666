import { observer } from "mobx-react";
import { FiSliders } from 'react-icons/fi';
import { useStores } from '@strategies/stores';
import { RadioSlider } from '@strategies/react-form';
import { Panel, Title, Toggle, Header, Body } from '@strategies/ui';
import { Timeline, Block, Scrubber, Segment } from '@strategies/react-timeline';

import Filters from '../Filters';
import TimeSlider from "../TimeSlider/TimeSlider";
import ProjectList from "../ProjectList/ProjectList";
import PanelSelect from "../PanelSelect";
import { fixedRectStyle } from '../../stores/LayoutStore';


export default observer(function TimelinePanel() {
    const { app, config, layout, persist, ui } = useStores();

    return (
        <div className="TimelinePanel" style={fixedRectStyle(layout.timelinePanel)}>
            <Panel
                active={ui.timelinePanelIsOpen}
                onToggle={() => ui.setTimelinePanelOpen(!ui.timelinePanelIsOpen)}
            >
                <Title>
                    <PanelSelect
                        value={app.timelineEnabled ? 'Timeline' : 'Project Cards'}
                        options={['Timeline', 'Project Cards']}
                        onChange={mode => app.setTimelineEnabled(mode === 'Timeline')}
                    />
                </Title>
                <Toggle><FiSliders /></Toggle>

                <Header>
                    <TimeSlider/>

                    <div className="color-selector">
                        <RadioSlider
                            value={app.colorMode.split('_').join(' ')}
                            options={Object.keys(config.colorBy).map(x => x.split('_').join(' '))}
                            onChange={mode => app.setColorMode(mode.split(' ').join('_'))}
                        />
                    </div>

                    <div className="timeline-menu">
                        <div className="menu-hamburger">
                            <div className="dot" />

                            <span>Menu</span>
                        </div>

                        <ul>
                            <li>
                                <button onClick={() => app.triggerBlockSort()}>Sort Blocks</button>
                            </li>
                        </ul>
                    </div>
                </Header>

                <Body>
                    <Filters/>

                    <div className="project-window">
                        {app.timelineEnabled ? (
                            <Timeline
                                startYear={config.startYear}
                                onCreateBlock={block => {
                                    const project = app.createProjectFromBlock(block);
                                    project.setSelected();
                                    ui.setProjectSettingsOpen();
                                    persist.addProject(project);
                                    app.triggerBlockSort();
                                }}
                            >
                                <Scrubber value={app.scrubber} onChange={value => app.setScrubber(value)} />

                                {app.includedProjects.map(project => (
                                    <Block
                                        key={project.id}
                                        name={project.name}
                                        color={project.color}
                                        proxy={project.block}
                                    >
                                        <Segment proxy={project.designSegment} />
                                    </Block>
                                ))}
                            </Timeline>
                        ) : (
                            <ProjectList/>
                        )}
                    </div>
                </Body>
            </Panel>
        </div>
    );
});
