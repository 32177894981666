import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import { SpecialProjectIcon, SpecialProjectType } from '../../models/SpecialProject';


export default observer(function SpecialProjects() {
    const { app, layout, ui } = useStores();
    const { projects } = app;

    return (
        <div className="SpecialProjects" style={layout.specialProjectsPosition}>
            {Object.values(SpecialProjectType).map(type => {
                const count = projects.filter(project => project.type === type).length;

                return (
                    <button key={type} onClick={() => ui.queueSpecialProject(type)}>
                        <img src={SpecialProjectIcon[type]} alt={type} />
                        {count > 0 && <span>{count}</span>}
                    </button>
                );
            })}
        </div>
    );
});
