import {TimelineStatus} from "./Project";

type DashiColorizerStateProps = {
    color?: string;
    timelineStatus?: string;
    isExcluded?: boolean;
    includedInCharts?: boolean;
    filteredOut?: boolean;
    selected?: boolean;
};

export default class DashiColorizerState {

    color: string;
    timelineStatus: string;
    isExcluded: boolean;
    includedInCharts: boolean;
    filteredOut: boolean;
    selected: boolean;

    constructor(props: DashiColorizerStateProps) {
        this.color = props.color || '#ff00ff';
        this.timelineStatus =  props.timelineStatus || TimelineStatus.NotStarted;
        this.isExcluded = props.isExcluded || false;
        this.includedInCharts = !!props.includedInCharts;
        this.filteredOut = props.filteredOut || false;
        this.selected = props.selected || false;
    }

}
