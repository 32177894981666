const firebaseConfig = {
    apiKey: "AIzaSyAVKH08WNSPZp6n9coTK1s2idnsXkwW1qQ",
    authDomain: "philly-rails-dashi.firebaseapp.com",
    projectId: "philly-rails-dashi",
    storageBucket: "philly-rails-dashi.appspot.com",
    messagingSenderId: "356186608412",
    appId: "1:356186608412:web:32182b5643c4ee801360d9",
};

export default firebaseConfig;
