import BitEncoder from "./BitEncoder";
import PixelReader from "./PixelReader";

export default class ColorizerEncodings {
    private startStopEncoder: BitEncoder;
    private colorEncoder: BitEncoder;
    private dataImage: CanvasImageSource;
    private pixelReader: PixelReader;

    constructor(dataImage: CanvasImageSource) {
        this.dataImage = dataImage;
        this.startStopEncoder = new BitEncoder();
        this.startStopEncoder.setRanges({start: 18, length: 6});

        this.colorEncoder = new BitEncoder();
        this.colorEncoder.setRanges({
            idx: 14,
            lightness: 8,
            type: ['NonColoredBg', 'ColoredFg', 'NonColoredFg', 'ColoredFgEdge']
        });

        this.pixelReader = new PixelReader();
    }

    getValuesForEncoded(r: number, g: number, b: number) {
        //find start/stop indices
        const {start, length} = this.startStopEncoder.decodeRGB(r, g, b);

        //find array of idx values at this pixel value
        return this.getDataValues(start, length);
    }

    getDataValues(start:number, length:number) {
        const image = this.dataImage;
        const tw:number = this.dataImage.width as number;
        const ans:any[] = [];

        for (let i = start; i < start + length; i++) {
            const x = i % tw;
            const y = Math.floor(i/tw);
            const [r, g, b] =  this.pixelReader.readPixel(image, x, y);
            let decoded = this.colorEncoder.decodeRGB(r, g, b);
            decoded.idx--;//correction for hack fix...
            ans.push(decoded);
        }
        return ans;
    }
}
