import { stores } from '@strategies/stores';import { computed, override } from 'mobx';
import { ExtendedModel, model, modelAction, prop } from 'mobx-keystone';

import DashiPeriod from '../../../core/models/Period';
import DashiProject, {IProjectData, TimelineStatus} from '../../../core/models/Project';
import {formatDollars} from "../../../core/util";


export const FINISH_LEVELS: { [key: string]: number } = {
    "Most Passive": .6,
    "Passive": .85,
    "Active": 1,
    "Most Active": 1.15,
}

export interface IRailsProjectData extends IProjectData {
    id: string;
    type: string;
    segment: number;
    mileLength: string;
    footLength: number;
    location: string;
    neighborhood: string;
    design: number;
    construction: number;
    cost: number;
    infrastructure: number;
    elevator?: number;
    elevatorCost: number;
    ramp?: number;
    rampCost: number;
    stairs?: number;
    stairsCost: number;
    ground?: number;
    groundCost: number | null;
    ownership: string | null;
    other: string | null;
}

export interface IRailsProject {
    totalCost: number;
    chartableCost: number[];
    isSpecialProject: boolean;
}

@model('rails/RailsProjectBase')
export class RailsProjectBase extends ExtendedModel(DashiProject, {}) implements IRailsProject {
    isSpecialProject: boolean = false;

    @computed
    get totalCost(): number {
        throw new Error('not implemented');
    }

    @computed
    get chartableCost(): number[] {
        const {app} = stores;
        const {startPeriod} = this;
        console.log('@computed chartableCost for start period:', startPeriod, this.name);

        return app.periods.map((period: DashiPeriod) => {
            return (period.i === startPeriod) ? this.totalCost : 0;
        })
    }

    @override
    get cardInfo(): { label:string, value:string }[] {
        return [
            {label: 'Cost', value: formatDollars(this.totalCost)}
        ];
    }
}

@model('rails/Project')
class RailsProject extends ExtendedModel(RailsProjectBase, {
    mileLength: prop<string>(),
    footLength: prop<number>(),
    location: prop<string>(),
    cost: prop<number>(),
    infrastructure: prop<number>(),
    elevator: prop<number>(0),
    elevatorCost: prop<number>(),
    ramp: prop<number>(0),
    rampCost: prop<number>(),
    segment: prop<number>(),
    stairs: prop<number>(0),
    stairsCost: prop<number>(),
    ground: prop<number>(0),
    groundCost: prop<number | null>(null),
    neighborhood: prop<string>(),
    ownership: prop<string | null>(null),
    other: prop<string | null>(null),
    access: prop<string[]>(() => []),
    finish_level: prop<string>('Active'),
}) implements IRailsProject {
    isSpecialProject: boolean = false;

    @modelAction
    setElevator(x: number) {
        this.elevator = x;
    }

    @modelAction
    setRamp(x: number) {
        this.ramp = x;
    }

    @modelAction
    setStairs(x: number) {
        this.stairs = x;
    }

    @modelAction
    setGround(x: number) {
        this.ground = x;
    }

    @computed
    get accessCost() {
        // @ts-ignore
        return ['elevator', 'ground', 'stairs', 'ramp'].reduce((cost: number, accessType: string) => cost + (this[accessType] * (this[`${accessType}Cost`] || 0)), 0) * this.escalation || 0;
    }

    @modelAction
    setFinishLevel(level: string) {
        if (!(level in FINISH_LEVELS)) {
            throw new Error('Invalid finish level: ' + level);
        }

        this.finish_level = level;
    }

    @computed
    get landscapeFinishCost() {
        return (this.cost - this.infrastructure) * FINISH_LEVELS[this.finish_level] * this.escalation;
    }

    @override
    get totalCost() {
        return this.accessCost + this.landscapeFinishCost + this.infrastructure ;// + (this.cost * this.escalation);
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setElevator(snapshot.elevator);
            this.setRamp(snapshot.ramp);
            this.setStairs(snapshot.stairs);
            this.setGround(snapshot.ground);
            this.setFinishLevel(snapshot.finish_level);
            this.setTimestamps(snapshot.timestamps);
        }
    }

    @computed
    get chartableFootLength(): number[] {
        const {app} = stores;
        const {start, end} = this.timestamps;

        return app.periods.map((period: DashiPeriod) => {
            return this.computeTimelineStatus(app.timelineEnabled, start, end, period.timeStamp) === TimelineStatus.Completed ? this.footLength : 0;
        })
    }

    @override
    get displayName() {
        return this.segment + ') ' + this.name;
    }
}


export default RailsProject;
