import { action, observable, makeObservable } from 'mobx';

import DashiUIStore from '../../../core/stores/UIStore';


export default class RailsUIStore extends DashiUIStore {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    queuedSpecialProject: string|null = null;

    @action
    queueSpecialProject(type: string|null) {
        console.log(type);
        this.queuedSpecialProject = type;
    }

}
