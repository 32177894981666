import {observer} from "mobx-react";
import {glslCore, ReglCanvas, ReglCanvasProps} from "./ReglCanvas";
import {ColorizerStateImages} from "./ColorizerStateImages";
import {GraphicIdLayer} from "../../../models/ColorizerImageLayer";


const frag = () => {
    // language=GLSL
    return `
        precision mediump float;

        ${glslCore()}

        uniform sampler2D textureId;
        uniform sampler2D textureCurrent;
        uniform sampler2D textureGraphic;
        uniform int idCount;

        varying vec2 uv;

        void main () {
            vec2 v_texcoord = vec2(uv.s, 1.0 - uv.t);
            vec4 encodedColor = texture2D(textureId, v_texcoord);

            int cVal = toValInt(encodedColor);
            int v_alpha = extractVal(cVal, 0, 8);
            int v_idx = extractVal(cVal, 8, 16);

            vec4 colorAtIdx = texture2D(textureCurrent, vec2((0.5+float(v_idx))/float(idCount), 0.5));
            vec4 displayColor;
            if (colorAtIdx.a == 0.) {
                displayColor = vec4(0);
            } else {
                vec4 graphicColor = texture2D(textureGraphic, v_texcoord);
                float a = float(v_alpha) / 255.;
                displayColor = vec4(graphicColor.r, graphicColor.g, graphicColor.b, a);
                if (colorAtIdx.r < 1. || colorAtIdx.g < 1. || colorAtIdx.b < 1.) { //pure white is used to represent 'pure' unmixed colors
//                    displayColor = vec4(blendScreen(displayColor.rgb, colorAtIdx.rgb), 1.);
                    displayColor = styleFromAlphaEncoding(displayColor, colorAtIdx.a, 1.);
                }
            }

            gl_FragColor = displayColor;
        }
    `
};
type LayerIdCanvasProps = ReglCanvasProps & {canvasId:string, layer:GraphicIdLayer};

export class LayerIdCanvas extends ReglCanvas<LayerIdCanvasProps> {
    colorStateImage?: ColorizerStateImages;

    drawConfig(regl:any, vert:string) {
        return {
            vert,
            frag: frag(),
            attributes: {
                position: [
                    0, 4,
                    -4, -4,
                    4, -4,
                ]
            },

            uniforms: {
                textureId: regl.prop('id'),
                textureGraphic: regl.prop('graphic'),
                textureCurrent: regl.prop('current'),
                idCount: regl.prop('idCount'),
            },

            count: 3
        }
    }

    drawFrameProps() {
        const {regl} = this;
        if (!regl || !this.colorStateImage) return;
        const { layer } = this.props;
        if (!layer) return null;
        const {canvas, update} = this.colorStateImage.updateGradient(layer.colorizerStates);
        if (!update) return null;
        const canvasGradTexture = regl.texture(canvas);
        return {
            current: canvasGradTexture,
            id: this.loadedTextures['id'],
            graphic: this.loadedTextures['graphic'],
            idCount: this.nArr,
        }
    }
    async loadImages(keyIndex:string[]) {
        const { regl } = this;
        if (!regl) return;
        const { layer } = this.props;
        if (!layer || !layer.paths) return;
        this.colorStateImage = new ColorizerStateImages(keyIndex, true);
        const { canvas } = this.colorStateImage.updateGradient(layer.colorizerStates);

        this.loadedTextures['id'] = regl.texture(await this.loadFromSrc(layer.paths.id));
        this.loadedTextures['graphic'] = regl.texture(await this.loadFromSrc(layer.paths.graphic));
        this.loadedTextures['current'] = regl.texture(canvas);
    };

    loadKeyIndex():string[] {
        const { layer } = this.props;
        if (!layer || !layer.paths) return [];

        return layer.paths.key;
    }

}

export default observer(LayerIdCanvas);
