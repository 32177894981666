import {observer} from "mobx-react";
import { useStores } from '@strategies/stores';

import DashiColorizerCanvas from "../../../models/ColorizerCanvas";
import { IImageLayer } from "../../../models/ColorizerImageLayer";

const zoomStyle = (imageWidth: number, imageHeight: number, zoom: number) => {
    const shift = {
        x: (imageWidth - (zoom * imageWidth)) / 2,
        y: (imageHeight - (zoom * imageHeight)) / 2,
    }
    shift.x /= zoom;
    shift.y /= zoom;
    return {
        width: imageWidth,
        height: imageHeight,
        transform: `scale(${zoom}) translate(${-shift.x}px,${-shift.y}px )`
    };//
};

type ColorizerStackProps = { id: string, dashiColorizerCanvas: DashiColorizerCanvas, position: number[], multipleCanvasMode: boolean, onButtonClick: () => void };
export default observer(function ColorizerStack({
                                                    id,
                                                    dashiColorizerCanvas,
                                                    position,
                                                    multipleCanvasMode,
                                                    onButtonClick
                                                }: ColorizerStackProps) {
    const { layout } = useStores();
    const {width, height} = dashiColorizerCanvas;

    const extraWidth = width * layout.width / layout.colorizerWindow.size.width;
    const extraHeight = height * layout.height / layout.colorizerWindow.size.height;

    const renderLayer = (layer: IImageLayer) => {//different image layers can have different image width/height, but we scale them all to match
        const {imageWidth, imageHeight} = layer;
        return <div key={layer.key} className={'stack scaler'} style={zoomStyle(imageWidth, imageHeight, width / imageWidth)}>
            {layer.element}
        </div>;
    };

    return (
        <div className={'ColorizerStack'+(multipleCanvasMode ? ' multi' : '')} style={{width, height, left: position[0], top: position[1]}}>
            <div id={id} className={'zoom-to-extra'} style={{width: extraWidth, height: extraHeight}}/>
            {multipleCanvasMode && <div className={'title'} onClick={onButtonClick}>
                {dashiColorizerCanvas.title}
            </div>}

            {dashiColorizerCanvas.layers.map((layer) => renderLayer(layer))}
        </div>
    );
});
