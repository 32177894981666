import DashiColorizerState from "../../../models/Colorizer";
import {TimelineStatus} from "../../../models/Project";

function hexToRgba(hex: string, a: number) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        a
    ] : [0, 0, 0, 0];
}

export type ColorHexAlpha = { color: string, alpha: number } | null;
export type GradientUpdateMethod = (colorizerStates: Map<string, DashiColorizerState>) => { canvas: any, update: boolean, values: ColorHexAlpha[] }

export class ColorizerStateImages {
    gradientCanvas: any;//TODO common interface for canvas?
    keyIndex: string[];
    imgData: any;

    constructor(keyIndex: string[], offscreen = true) {
        this.keyIndex = keyIndex;

        this.gradientCanvas = document.createElement('canvas');
        this.gradientCanvas.id = 'onscreen-debug-canvas';
        this.gradientCanvas.width = this.idxCount;
        this.gradientCanvas.height = 1;
        this.gradientCanvas.style = offscreen ? 'display:none' : 'zoom:10';
        // gradientCanvas.style = 'zoom:10; image-rendering: pixelated;';
        document.body.appendChild(this.gradientCanvas);

        this.setupGradient();

    }

    get idxCount() {
        return this.keyIndex.length;
    }

    setupGradient() {
        const ctx = this.gradientCanvas.getContext("2d");
        if (!ctx) return;
        this.imgData = ctx.createImageData(this.idxCount, 1);
    }

    updateGradient(colorizerStates: Map<string, DashiColorizerState>) {
        const values:ColorHexAlpha[] = [];
        const ctx = this.gradientCanvas.getContext("2d");

        const {keyIndex} = this;
        for (let i = 0; i < keyIndex.length; i++) {
            const id = keyIndex[i];
            const arrIdx = i + 1;//HACK fix to work around shader issue
            let rgb = [0, 0, 0, 0];
            values[i] = null;
            if (colorizerStates && colorizerStates.get) {
                const colorizerState = colorizerStates.get(id);
                if (colorizerState) {
                    let colorHex = {color: colorizerState.color, alpha: 1};

                    if (colorHex) {
                        if (colorizerState.filteredOut) {
                            colorHex.color = '#aaaaaa';
                            colorHex.alpha = 0.1;//below 0.2 we just use plain alpha 0.1 = 50% etc
                        }
                        if (colorizerState.timelineStatus === TimelineStatus.NotStarted) {
                            colorHex.alpha = 0.125;
                        }
                        if (colorizerState.timelineStatus === TimelineStatus.Underway) {
                            colorHex.alpha = 0.25;
                        }
                        if (colorizerState.selected) {
                            colorHex.alpha = 0.8;
                        }
                        if (colorizerState.timelineStatus === TimelineStatus.Underway && colorizerState.selected) {
                            colorHex.alpha = 0.85;
                        }
                        values[i] = colorHex;
                        rgb = hexToRgba(colorHex.color, colorHex.alpha * 255);
                    }
                }
            }

            const idx = arrIdx * 4;
            this.imgData.data[idx + 0] = rgb[0];
            this.imgData.data[idx + 1] = rgb[1];
            this.imgData.data[idx + 2] = rgb[2];
            this.imgData.data[idx + 3] = rgb[3];

        }
        ctx.putImageData(this.imgData, 0, 0);
        return {canvas: this.gradientCanvas, update: true, values};
    }
}
