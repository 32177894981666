import { stores } from '@strategies/stores';
import { ExtendedModel ,getSnapshot, model, modelAction, prop } from 'mobx-keystone';

import DashiPersistStore from '../../../core/stores/PersistStore';
import { IRailsProjectData } from '../models/Project';

import data from '../assets/projects.json';
import RailsAppStore from './AppStore';


@model('rails/PersistStore')
class RailsPersistStore extends ExtendedModel(DashiPersistStore, {
    fundraising: prop<number>(50000000),
}) {

    @modelAction
    setFundraising(fundraising: number) {
        this.fundraising = fundraising;
    }

    @modelAction
    seed() {
        const { app, config } = stores;

        this.projects = data.map((row, i) => {
            const project: IRailsProjectData = {
                ...row,
                isExcluded: true,
                timelineY: i * config.timelineBlockHeight,
                timestamps: {
                    start: row.design * config.periodScale,
                    end: (row.construction + row.design) * config.periodScale,
                    design: 0,
                },
            }

            if (row.defaultAccess) {
                // @ts-ignore
                project[row.defaultAccess.toLowerCase()] = 1;
            }

            return (app as RailsAppStore).createProject(project);
        });
    }

    save() {
        if (!this.exists) {
            this.setCreatedBy(stores.user.displayName);
        }

        const snapshot = getSnapshot(this);
        snapshot.projects.forEach(project => project.metrics = null);

        this.setModified(Date.now());
        this.db.collection(this.collection).doc(this.id).set(snapshot);
        this.setExists(true);
    }

}

export default RailsPersistStore;
