import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import Checkbox from '../Checkbox/Checkbox';


export default observer(function Filters() {
    const { app, config } = useStores();

    const setFilter = useCallback((e, key, checked) => {
        const state = { ...app.filters };
        const filter = app.colorMode;

        if (e.ctrlKey) {
            state[filter] = Object.keys(config[config.colorBy[app.colorMode]]).filter(_key => _key !== key);
        }
        else {
            if (checked) {
                state[filter].splice(state[filter].indexOf(key), 1);

                if (state[filter].length === 0) {
                    delete state[filter];
                }
            }
            else {
                if (!(filter in state)) {
                    state[filter] = [];
                }

                state[filter].push(key);
            }
        }

        app.setFilters(state);
    }, [app, config]);

    const isChecked = useCallback(key => {
        const filter = app.filters[app.colorMode];

        return !(filter && filter.indexOf(key) !== -1);
    }, [app]);

    return (
        <div className="Filters">
            <ul>
                {Object.entries(config[config.colorBy[app.colorMode]]).map(([key, backgroundColor]) => (
                    <li key={key} style={{ backgroundColor }} onClick={e => setFilter(e, key, !isChecked(key))}>
                        <Checkbox value={isChecked(key)} />
                        {key}
                    </li>
                ))}
            </ul>
        </div>
    );
});
