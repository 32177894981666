import {computed, override} from 'mobx';
import { ExtendedModel, model, modelAction, prop } from 'mobx-keystone';
import DashiProject from '../../../core/models/Project';

import CafeIcon from '../assets/images/cafe.png';
import RestroomsIcon from '../assets/images/restrooms.png';
import BeaconIcon from '../assets/images/beacon.png';
import {IRailsProject, RailsProjectBase} from "./Project";


export const SpecialProjectType: {[key:string]: string} = {
    restrooms: 'Restrooms',
    cafe: 'Cafe / Flexible Building',
    beacon: 'Signature Beacon / Art Pieces',
}

export const SpecialProjectIcon: {[key:string]: any} = {
    [SpecialProjectType.cafe]: CafeIcon,
    [SpecialProjectType.restrooms]: RestroomsIcon,
    [SpecialProjectType.beacon]: BeaconIcon,
}

export const SpecialProjectCost: {[key:string]: number} = {
    [SpecialProjectType.cafe]: 1380000,
    [SpecialProjectType.restrooms]: 1104000,
    [SpecialProjectType.beacon]: 287500,
};

export const SpecialProjectTimestamp: {[key:string]: {[key:string]: number}} = {
    [SpecialProjectType.cafe]: {
        design: 24,
        construction: 24,
    },
    [SpecialProjectType.restrooms]: {
        design: 18,
        construction: 18,
    },
    [SpecialProjectType.beacon]: {
        design: 15,
        construction: 12,
    },
};

@model('rails/SpecialProject')
class SpecialProject extends ExtendedModel(RailsProjectBase, {
    cost: prop<number>(0)
}) implements IRailsProject {

    isSpecialProject: boolean = true;

    @override
    get totalCost() {
        return this.cost;
    }

    get finish_level() {
        return "Not Applicable";
    }

    get ownership() {
        return "Not Applicable";
    }

    @modelAction
    setType(type: string) {
        this.type = type;
        this.cost = SpecialProjectCost[type];
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setType(snapshot.type);
            this.setTimestamps(snapshot.timestamps);
        }
    }
}

export default SpecialProject;
