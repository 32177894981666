import { useStores } from '@strategies/stores';
import { observer } from 'mobx-react';
import { Modal, Title, Body, Buttons, Button } from '@strategies/ui';



export default observer(function InfoModal() {
    const { config, ui, user } = useStores();

    return (
        <Modal
            className='InfoModal'
            active={ui.infoModalIsOpen}
            onClose={() => ui.setInfoModalOpen(false)}
        >
            <Title>Dashboard Information</Title>

            <Body>
                <ul>
                    <li>
                        <label>Product</label>
                        <span>{config.product}</span>
                    </li>

                    <li>
                        <label>Project</label>
                        <span>{config.project}</span>
                    </li>

                    {config.source && (
                        <li>
                            <label>Data Source</label>
                            <span>{config.source}</span>
                        </li>
                    )}

                    {config.contact && (
                        <li>
                            <label>Contact</label>
                            <span>{config.contact}</span>
                        </li>

                    )}

                    {config.userGuide && (
                        <li>
                            <label>User Guide</label>
                            <span><a rel="noopener noreferrer" target="_blank" href={config.userGuide}>{config.userGuide}</a></span>
                        </li>
                    )}
                </ul>
            </Body>

            <Buttons>
                <button className="text" onClick={() => user.logout()}>
                    Logout {user.displayName}
                </button>

                <Button onClick={() => ui.setInfoModalOpen(false)}>
                    Done
                </Button>
            </Buttons>
        </Modal>
    );
});
