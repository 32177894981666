import { stores } from '@strategies/stores';
import { computed, makeObservable } from 'mobx';


export default class DashiPeriod {

    i: number;

    constructor(i: number) {
        makeObservable(this);
        this.i = i;
    }

    @computed
    get label() {
        const { startYear } = stores.config;
        return `'${startYear - 2000 + Math.floor(this.i / 4)}`;//TODO check if this should be i+1
    }

    @computed
    get timeStamp() {
        const {config} = stores;
        return config.periodScale * this.i;
    }

}
