import { stores } from '@strategies/stores';
import { makeObservable, override } from 'mobx';
import { observer } from 'mobx-react';

import JobsChart from './JobsChart';
import RailsAppStore from '../stores/AppStore';


@observer
class OccupationsResidents extends JobsChart {

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    static get label() {
        return "Ppl Living By Industry";
    }

    static get title() {
        return "Occupations For Neighborhood Residents";
    }

    get colors() {
        return {
            value: '#CFB87C',
        };
    }

    @override
    get input() {
        return (stores.app as RailsAppStore).outputs.live;
    }

}

export default OccupationsResidents;
