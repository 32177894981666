import { time } from '@strategies/react-timeline';
import { SpecialProjectType } from './models/SpecialProject';

import ElevatorAccess from './assets/images/access_elevator-01.png';
import RampAccess from './assets/images/access_ramp-01.png';
import StairAccess from './assets/images/access_stair-01.png';
import GroundAccess from './assets/images/access_ground-01.png';
import SegmentNumber from './assets/images/segmentnumber.png';
import ComplexProject from './assets/images/alert-01.png';


const CUBConfig: any = {

    client: 'Philadelphia Rail',

    project: 'The Rail Park',
    contact: 'strategies@sasaki.com',

    userGuide: 'https://docs.google.com/document/d/e/2PACX-1vSATt3xg99H3q8pjHlXMqixDhvpLrUZpwulihgH_OeOpbQjuhNckwwFEhx850pvTV6KRytQaGldTkcB/pub',

    colorBy: {
        "type": 'TypeColors',
        "finish_level": "FinishLevelColors",
        "ownership": "OwnershipColors",
    },

    legend: {
        "Elevator Access": ElevatorAccess,
        "Ramp Access": RampAccess,
        "Stair Access": StairAccess,
        "Ground Access": GroundAccess,
        "Segment Number": SegmentNumber,
        "Complex Project": ComplexProject,
    },

    colorizerDataWidth: 512,

    periodScale: time.MONTH,

    hideTimelineInCardView: true,
    timelineSelectedByDefault: false,

    startYear: 2022,
    totalPeriods: (2040 - 2022) * 12,

    TypeColors: {
        "Soft Cut": "#DFB307",
        "Tunnel": "#9C1122",
        "Hard Cut": "#A5C40E",
        "Viaduct": "#FF740A",
        [SpecialProjectType.cafe]: "#27B9A8",
        [SpecialProjectType.restrooms]: "#27B9A8",
        [SpecialProjectType.beacon]: "#27B9A8",
    },

    FinishLevelColors: {
        "Most Passive": "#A0CC37",
        "Passive": "#E9C732",
        "Active": "#F7941D",
        "Most Active": "#F25A29",
        "Not Applicable": "#817F9B",
    },

    OwnershipColors: {
        "SEPTA": "#75E6CD",
        "SEPTA Volumetric": "#8FD487",
        "Philadelphia & Reading Railroad Co": "#A3A8FF",
        "School District of Philadelphia": "#E675CA",
        "Not Applicable": "#817F9B",
    },

};

export default CUBConfig;
